<style lang="less">
@import "./resourcemenu.less";
</style>

<template>
  <div id="projectWrapper">
      <div class="p-right-container">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      viewerpath: "",
      routername: "",
      minVal: "248px",
      leftBarShrink: false,
      splitVal: "248px",
      title: "",
      projectId: null,
      workspaceId: null,
      projectInfo: {},
      workspaceInfo: {},
      encodePramas: "",
    };
  },
  computed: {},
  methods: {
    
    init() {
      this.$parent.navPaths = [
        {
          type: "break",
        },
        {
          type: "title",
          title: this.$t('x_overview_resourve'),
        },
      ];

    },

  },
  watch: {
    $route(to) {
      this.routername = to.name;
      if (_.startsWith(to.name, "project")) {
        this.workspaceId = null;
        this.workspaceInfo = {};
      }
    },
  },
  mounted() {
    this.routername = this.$route.name;
    this.init();
  },
};
</script>
